/**
 * ----------------------------------------------------------------
 * FooterComponent
 *
 * Contient les élements à afficher dans la partie basse de la page
 *
 * styles: "/scss/layouts/_footer.scss"
 * ----------------------------------------------------------------
 */

function FooterComponent() {

    return(
        <footer>
        <div id="Footer-inner">
            <svg width="122" height="40" viewBox="0 0 122 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6445 20.0984L5.12785 26.762V36.1125H0V0H5.12785V20.2058L24.5709 0H30.4466L15.1699 16.2292L31.5149 36.1125H25.5324L11.6445 20.0984Z" fill="white"/>
                <path d="M68.9054 33.2106L70.9352 29.3414C73.2854 30.9536 76.9177 32.1358 80.4431 32.1358C85.0367 32.1358 86.8529 30.7386 86.8529 28.3741C86.8529 22.2479 69.6532 27.5143 69.6532 16.659C69.6532 11.7151 74.0332 8.49072 80.9772 8.49072C84.5026 8.49072 88.5621 9.45802 90.9124 10.9627L88.7758 14.8319C86.3187 13.2197 83.648 12.6824 80.9772 12.6824C76.704 12.6824 74.5674 14.2945 74.5674 16.4441C74.5674 22.8928 91.767 17.6263 91.767 28.2666C91.767 33.2106 87.2802 36.3275 80.0157 36.3275C75.6357 36.435 71.1488 35.0377 68.9054 33.2106Z" fill="white"/>
                <path d="M122 19.561V36.1126H117.299V32.4583C115.697 34.9303 112.599 36.3275 108.326 36.3275C102.13 36.3275 98.2837 32.9957 98.2837 28.2667C98.2837 23.8601 101.061 20.3133 109.18 20.3133H116.979V19.346C116.979 15.1544 114.522 12.7899 109.715 12.7899C106.51 12.7899 103.198 13.9721 101.061 15.6918L99.0315 11.9301C101.809 9.67304 105.869 8.3833 110.249 8.3833C117.834 8.49078 122 12.145 122 19.561ZM117.086 27.7293V23.8601H109.501C104.587 23.8601 103.198 25.7947 103.198 28.0517C103.198 30.7387 105.441 32.4583 109.18 32.4583C112.919 32.5658 115.911 30.9536 117.086 27.7293Z" fill="white"/>
                <path d="M53.0946 28.9116V36.2201L56.1927 34.393V27.0845L53.0946 28.9116Z" fill="white"/>
                <path d="M61.641 19.9909L48.9282 12.5749L41.8774 8.49072L34.9335 20.6357L35.0403 32.0284L47.6463 39.4443L48.9282 38.692V27.9442L54.5902 17.9488L60.359 21.2806V32.0284L61.641 31.276V19.9909Z" fill="white"/>
            </svg>
            <p id="footer-copyright">© 2020 Kasa. All rights reserved</p>
        </div>
    </footer>
    );

}

export default FooterComponent;